@media only screen and (max-width:47.9rem) {

    .footer_container{
        height: 25rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        padding: 6.25rem 1.5rem;
    }

    .footer_text{
        font-size: 2rem;
    }

    .footer_btn{
        height: 64px;
        width: 12.4375rem;
        background-color: var(--red);
        color: white;
        font-family: "Commissioner", sans-serif;
        font-size: 1rem;
        font-weight: 800;
        border: none;
        outline: none;
    }

}

@media only screen and (min-width:48rem){

    .footer_container{
        height: 10rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6.25rem 1.5rem;
    }

    .footer_text{
        font-size: 2rem;
        width: 20rem;
    }

    .footer_btn{
        height: 64px;
        width: 12.4375rem;
        background-color: var(--red);
        color: white;
        font-family: "Commissioner", sans-serif;
        font-size: 1rem;
        font-weight: 800;
        border: none;
        outline: none;
        cursor: pointer;
    }

}

@media only screen and (min-width:68.75rem){

    .footer_container{
        height: 10rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6.25rem 8rem;
        background-image: url(../assets//desktop/bg-pattern-wave-red.svg);
        background-position: -5rem 5rem;
        background-repeat: no-repeat;
    }

    .footer_text{
        width: 34.6875rem;
        font-size: 3.5rem;
    }

    .footer_btn{
        transition: ease-in-out 0.2s;
    }

    .footer_btn:hover{
        background-color: var(--red-hover);
        transition: ease-in-out 0.2s;
    }

}