@media only screen and (max-width:47.9rem){

    nav{
        height: 6.875rem;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav_white{
        width: 100vw;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 1.5625rem;
    }

    .nav_red_active{
        position: absolute;
        height: 18.25rem;
        width: 15.75rem;
        top: 6.875rem;
        right: 1.5625rem;
        background-color: var(--bg-black);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1.5rem 0rem;
        transition: ease-in-out 0.5s;
    }

    .nav_red_inactive{
        position: absolute;
        height: 18.25rem;
        width: 15.75rem;
        top: 6.875rem;
        right: 100%;
        background-color: var(--bg-black);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1.5rem 0rem;
        transition: ease-in-out 0.5s;
    }

    .nav_list{
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        list-style: none;
    }

    .nav_link{
        text-decoration: none;
        color: var(--white);
        text-transform: capitalize;
    }

    .hamburger_btn{
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .scedule_call_nav_btn{
        width: 12.4375rem;
        height: 4rem;
        background-color: var(--red);
        color: var(--white);
        border: none;
        outline: none;
        font-family: "Commissioner";
        font-size: 1rem;
        font-weight: 800;
    }

}

@media only screen and (min-width:48rem){

    .hamburger_btn{
        display: none;
    }

    nav{
        height: 11.125rem;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .nav_white{
        height: 100%;
        width: calc(100vw - 20.625rem);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 2.0625rem;
    }
    
    .nav_red_inactive{
        background-color: var(--red);
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 2.0625rem;
    }
    
    .nav_list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 22.8125rem;
        list-style: none;
    }
    
    .nav_link{
        text-decoration: none;
        color: var(--white);
        text-transform: capitalize;
    }
    
    .scedule_call_nav_btn{
        width: 9.1875rem;
        height: 4rem;
        background-color: var(--black);
        color: var(--white);
        border: none;
        outline: none;
        font-family: "Commissioner";
        font-size: 1rem;
        font-weight: 800;
    }

}

@media only screen and (min-width:68.75rem){

    nav{
        height: 11.125rem;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .hamburger_btn{
        display: none;
    }

    .nav_logo_img{
        padding-left: 10.3125rem;
    }
    
    .nav_white{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0rem;
    }
    
    .nav_red_inactive{
        background-color: var(--red);
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 4.3125rem;
    }
    
    .nav_list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 29.4375rem;
        list-style: none;
    }
    
    .nav_link{
        text-decoration: none;
        color: var(--white);
        text-transform: capitalize;
    }

    .nav_link:hover{
        font-weight: 800;
    }
    
    .scedule_call_nav_btn{
        width: 12.4375rem;
        height: 4rem;
        background-color: var(--black);
        color: var(--white);
        border: none;
        outline: none;
        font-family: "Commissioner";
        font-size: 1rem;
        font-weight: 800;
        cursor: pointer;
        transition: ease-in-out 0.2s;
    }

    .scedule_call_nav_btn:hover{
        background-color: var(--bg-black);
        transition: ease-in-out 0.2s;
    }

}