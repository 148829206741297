@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@400;800&display=swap');

*,
*::before,
*::after{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  font-size: 18px;
  font-family: "Commissioner", sans-serif;
}